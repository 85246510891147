import React, { useEffect, useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { navigate } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import { getRegistrationDates } from '../../utils/datetime'
import { Countries, Regions } from '../../utils/constants'
import { getCookie } from '../../utils/cookies'
import { EnvConfig } from '../../config/EnvConfig'
import { doRegistration } from '../../adapters/auth'
import {
  getCurrencies,
  getLanguages,
  getCountries,
} from '../../adapters/common'

import SplitLayout from '../../components/common/splitLayout'
import Seo from '../../components/common/seo'
import media from '../../components/css/media'
import Btn from '../../components/common/button'
import Error from '../../components/common/errorMessage'

import useDeviceDetect from '../../hooks/useDeviceDetect'
import allLocales from '../../i18n/locales'
import {
  passwordRegex,
  handlePasswordKeyDown,
} from '../../utils/passwordValidation'

import SuccessIcon from '../../images/success_icon.svg'
import EyeIcon from '../../images/icon_eye.svg'
import EyeSlashIcon from '../../images/icon_eye_slash.svg'
import ChevronLeftIcon from '../../images/icon_chevron_left.svg'

// needed for using dayjs.utc()
dayjs.extend(utc)

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  background-color: #ffffff;

  form {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
  }

  @media (max-height: 840px) {
    justify-content: initial;
    padding: 2rem 0;
  }

  ${media.desktop`
    form {
      max-width: 500px;
    }
  `};
`

const ProgressBar = styled.ul`
  overflow: hidden;
  margin: 0.2rem auto 2rem;
  width: 88px;
`

const ProgressBarStep = styled.li`
  list-style-type: none;
  width: 22px;
  float: left;
  position: relative;
  z-index: 1;

  &:before {
    width: 14px;
    height: 14px;
    content: '';
    counter-increment: step;
    border: 3px solid #000;
    display: block;
    border-radius: 50%;
    background-color: #000;
    box-sizing: border-box;
  }

  &:after {
    width: 25px;
    height: 3px;
    content: '';
    position: absolute;
    background-color: #000;
    top: 6px;
    z-index: -1;
    box-sizing: border-box;
  }

  &:last-child {
    &:after {
      width: 0;
    }
  }

  &.active {
    &:before {
      background-color: #fad749;
    }
  }
`

const Eye = styled.img`
  width: 20px;
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
`

const EyeSlash = styled.img`
  width: 20px;
  position: absolute;
  right: 5%;
  top: 15px;
  cursor: pointer;
`

const ChevronLeft = styled.img`
  width: 12px;
  cursor: pointer;
`

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 0 0 0.8rem 0;
`

const Row = styled.div`
  display: flex;
  gap: 20px;
`

const Col = styled.div.attrs((props) => ({
  width: props.width || '50%',
}))`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => props.width};

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &:not(:last-child) {
      margin-right: 5%;
    }
  }
`

const PasswordWrapper = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 25%;
    right: 5%;
    cursor: pointer;
  }
`

const Back = styled.div`
  position: absolute;

  svg {
    cursor: pointer;
  }
`

const Email = styled.div`
  font-weight: 600;
  margin: 1rem 0 0 0;
`

const CheckboxWrapper = styled.div`
  font-size: 0.9em;
  display: flex;
  margin: 0 0 1rem 0;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;

    &:checked ~ label {
      &:before {
        background-color: #fff;
        border: 3px solid #31c27c;
        color: #31c27c;
        background-image: url('${SuccessIcon}');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;
      }
    }
  }

  label {
    padding: 0 0 0 3rem;

    &:before {
      display: block;
      position: absolute;
      user-select: none;
      height: 32px;
      width: 32px;
      border-radius: 6px;
      background-color: #eee;
      border: 3px solid #dbdbdb;
      pointer-events: none;
      content: '';
      left: 0;
      top: 0;
      box-sizing: border-box;
    }

    &:after {
      content: '';
      left: 0;
      top: 0;
      background: no-repeat 50%/50% 50%;
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
    }
  }

  &.invalid {
    label {
      &:before {
        border: 3px solid #ff8282;
      }
    }
  }
`

const Register = () => {
  const { isMobile } = useDeviceDetect()
  const intl = useIntl()

  const data = useStaticQuery(graphql`
    {
      otherBannersApi {
        values {
          lang
          otherBanners {
            background
            backgroundMobile
            banner_title_text
            text_color
            type
          }
        }
      }
      customSlugsApi {
        values {
          lang
          slugs {
            generic_route
            language_route
          }
        }
      }
    }
  `)

  const {
    otherBannersApi: { values },
    customSlugsApi: { values: customSlugValues },
  } = data

  const bannerExistForLocale = values.some((row) => row.lang === intl.locale)
  const bannerExistForPage =
    bannerExistForLocale &&
    values
      .filter((row) => row.lang === intl.locale)[0]
      .otherBanners.some((i) => i.type === 'registration')
  const banner = bannerExistForPage
    ? values
        .filter((row) => row.lang === intl.locale)[0]
        .otherBanners.filter((i) => i.type === 'registration')[0]
    : null

  const slugsExistForLocale = customSlugValues.some(
    (row) => row.lang === intl.locale
  )
  const slugsExistForPage =
    slugsExistForLocale &&
    customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
  const slugs = slugsExistForPage
    ? customSlugValues.filter((row) => row.lang === intl.locale)[0].slugs
    : []

  const loginSlug = slugs.some(
    (slug) => slug.generic_route.toLowerCase() === 'login'
  )
    ? slugs.filter((slug) => slug.generic_route.toLowerCase() === 'login')[0]
        .language_route
    : 'login'

  const image = banner ? banner.background : null
  const imageMobile = banner ? banner.backgroundMobile : null
  const text = banner ? banner.banner_title_text : null
  const textColor = banner ? banner.text_color : null

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useForm()

  const [response, setResponse] = useState(null)
  const [formStep, setFormStep] = useState(0)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [tandcChecked, setTandcChecked] = useState(false)
  const [marketingChecked, setMarketingChecked] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [languages, setLanguages] = useState([])
  const [countries, setCountries] = useState([])
  const [mainCurrencies, setMainCurrencies] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [phoneCodes, setPhoneCodes] = useState([])
  const [regions, setRegions] = useState([])
  const [dates] = useState(getRegistrationDates())

  const password = useRef({})
  password.current = watch('password', '')

  const email = useRef({})
  email.current = watch('email', '')

  async function loadCountries() {
    const response = await getCountries()

    if (response.ok) {
      let codes = []
      response.data.data.forEach((c) => {
        if (c.phone_prefix !== '') {
          codes.push(`+${c.phone_prefix}`)
        }
      })
      codes.sort()

      let uniqueCodes = [...new Set(codes)]
      setPhoneCodes(uniqueCodes)

      setCountries(
        response.data.data.filter(
          (c) =>
            c.id === Countries.BRAZIL ||
            c.id === Countries.CHILE ||
            c.id === Countries.MEXICO ||
            c.id === Countries.CANADA ||
            c.id === Countries.PERU ||
            c.id === Countries.INDIA ||
            c.id === Countries.JAPAN
        )
      )
    }
  }

  async function loadCurrencies() {
    const response = await getCurrencies()

    if (response.ok) {
      setMainCurrencies(response.data.data)
    }
  }

  async function loadLanguages() {
    const response = await getLanguages()

    if (response.ok) setLanguages(response.data.data)
  }

  const filterCurrencies = useCallback(
    (value) => {
      if (value !== '' && countries.length > 0) {
        const currencies = countries.filter((c) => c.id === parseInt(value))[0]
          .currency_list

        setCurrencies(currencies)
        setRegions(Regions[value])
      }
    },
    [setCurrencies, setRegions, countries]
  )

  const onError = (_) => {}
  const onChange = (_) => {}

  const onSubmit = async (data) => {
    const tandcValidation = await trigger('tandc')
    const addressValidation = await trigger('address')
    const postcodeValidation = await trigger('postcode')
    const cityValidation = await trigger('city')
    const countryValidation = await trigger('country')
    const currencyValidation = await trigger('currency')

    if (
      !addressValidation ||
      !postcodeValidation ||
      !cityValidation ||
      !countryValidation ||
      !currencyValidation ||
      !tandcValidation
    )
      return

    const clickid = getCookie('clickid')
    const curr = mainCurrencies.filter((c) => c.short_code === data.currency)

    const langCode = allLocales[intl.locale].code
    const language = languages.filter((l) => l.code.toLowerCase() === langCode)

    data.month =
      parseInt(data.month) < 10 ? data.month.padStart(2, '0') : data.month
    data.day = parseInt(data.day) < 10 ? data.day.padStart(2, '0') : data.day

    const dob = undefined
    let dateOfBirth =
      data.year === undefined && dob !== undefined
        ? dob
        : `${data.year}-${data.month}-${data.day}`

    const model = {
      email: data.email.trim().slice(0, 100),
      password: data.password,
      confirm_password: data.confirmPassword,
      currency_id: curr.length > 0 ? curr[0].id : 0,
      country_id: parseInt(data.country),
      user_language_id: language[0].id,
      website_language_id: language[0].id,
      city: data.city.slice(0, 90),
      street: data.address.slice(0, 128),
      region: data.region,
      postcode: data.postcode.slice(0, 16),
      title: '-',
      first_name: data.name.slice(0, 50),
      middle_name: '',
      last_name: data.lastName.slice(0, 50),
      birthday: dayjs.utc(dateOfBirth).toISOString(),
      gender: '-',
      mobile_prefix: data.mobilePrefix,
      mobile_number: data.mobileNumber.slice(0, 32),
      phone_prefix: data.mobilePrefix,
      phone_number: data.mobileNumber.slice(0, 32),
      security_question: 'KTO.com',
      security_answer: 'KTO.com',
      affiliate_marker: data.couponCode ? data.couponCode.slice(0, 50) : '-',
      click_id: clickid ? clickid : null,
      age_check: true,
      accept_tc: tandcChecked,
      accept_pp: tandcChecked,
      accept_gdpr: tandcChecked,
      accept_sms: !data.marketing,
      accept_news: !data.marketing,
      accept_gdpr_marketing: !data.marketing,
      activation_url: `${EnvConfig.SITE_URL}/${intl.locale}/activate/`,
    }

    setDisabled(true)

    const response = await doRegistration(model)
    setResponse(response)

    if (response.ok) {
      setDisabled(false)
      setFormStep(formStep + 1)

      if (typeof window !== 'undefined') {
        window.dataLayer.push({
          event: 'Registration',
          accountId: response.data.id,
        })
      }
    }

    if (!response.ok) {
      setDisabled(false)
    }
  }

  const handleMobileNumberChange = (e) => {
    let mobileNum = e.target.value.replace(/\D/g, '')
    e.target.value = mobileNum
    setValue('mobileNumber', mobileNum)
  }

  const handleCountryChange = (e) => {
    if (e.target.value === '') {
      setCurrencies([])
      setRegions([])
    }

    setValue('country', e.target.value)
    filterCurrencies(e.target.value)
    setValue('currency', '')
    setValue('region', '')
    setCountries((countries) => [...countries]) // force rerender
  }

  const handleCurrencyChange = (e) => {
    setValue('currency', e.target.value)
    setCountries((countries) => [...countries]) // force rerender
  }

  const handleRegionChange = (e) => {
    setValue('region', e.target.value)
    setCountries((countries) => [...countries]) // force rerender
  }

  useEffect(() => {
    const fetchData = async () => {
      await loadCountries()
      await loadLanguages()
      await loadCurrencies()
    }

    fetchData()
  }, [])

  useEffect(() => {
    const btag = getCookie('btag')
    const couponCode = document.getElementById('couponCode')
    if (couponCode) {
      setValue('couponCode', btag)
      couponCode.value = btag
    }
  }, [formStep, setValue])

  useEffect(() => {
    const setDefaultValues = () => {
      switch (navigator.language.toLowerCase()) {
        case 'pt-br':
          setValue('country', Countries.BRAZIL)
          filterCurrencies(Countries.BRAZIL)
          setValue('currency', 'BRL')
          setValue('mobilePrefix', '+55')
          break
        case 'es-pe':
          setValue('country', Countries.PERU)
          filterCurrencies(Countries.PERU)
          setValue('currency', 'PEN')
          setValue('mobilePrefix', '+51')
          break
        case 'es-cl':
          setValue('country', Countries.CHILE)
          filterCurrencies(Countries.CHILE)
          setValue('currency', 'CLP')
          setValue('mobilePrefix', '+56')
          break
        case 'en-ca':
          setValue('country', Countries.CANADA)
          filterCurrencies(Countries.CANADA)
          setValue('currency', 'CAD')
          setValue('mobilePrefix', '+1')
          break
        case 'es-mx':
          setValue('country', Countries.MEXICO)
          filterCurrencies(Countries.MEXICO)
          setValue('currency', 'MXN')
          setValue('mobilePrefix', '+52')
          break
        case 'hi-in':
          setValue('country', Countries.INDIA)
          filterCurrencies(Countries.INDIA)
          setValue('currency', 'INR')
          setValue('mobilePrefix', '+91')
          break
        case 'ja-jp':
          setValue('country', Countries.JAPAN)
          filterCurrencies(Countries.JAPAN)
          setValue('currency', 'JPY')
          setValue('mobilePrefix', '+81')
          break
        default:
          break
      }
    }

    setDefaultValues()
  }, [countries, filterCurrencies, setValue])

  const [showPasswordLabel, setShowPasswordLabel] = useState(true)

  const validatePassword = (value) => {
    const userEmail = email.current
    if (!value) {
      setShowPasswordLabel(true)
      return
    }
    const isNotValid =
      value?.toLowerCase()?.includes(userEmail?.toLowerCase()) ||
      userEmail?.toLowerCase()?.includes(value?.toLowerCase())
    setShowPasswordLabel(!isNotValid)
    return !isNotValid || intl.formatMessage({ id: 'common.passwordHint' })
  }

  return (
    <SplitLayout
      img={isMobile ? imageMobile : image}
      text={text}
      textColor={textColor}
      mobileBannerStyle='short'
      hideLeft={isMobile && formStep > 0}
    >
      <Seo
        title={intl.formatMessage({ id: `seo.register_title` })}
        description={intl.formatMessage({ id: `seo.register_description` })}
      />
      <Content>
        <form id='register-form' onSubmit={handleSubmit(onSubmit, onError)}>
          {formStep > 0 && formStep < 3 && (
            <Back>
              <ChevronLeft
                src={ChevronLeftIcon}
                alt='Back'
                onClick={() => setFormStep(formStep - 1)}
              />
            </Back>
          )}

          {formStep < 3 && (
            <ProgressBar>
              <ProgressBarStep className={`${formStep >= 0 ? 'active' : ''}`} />
              <ProgressBarStep className={`${formStep >= 1 ? 'active' : ''}`} />
              <ProgressBarStep className={`${formStep >= 2 ? 'active' : ''}`} />
            </ProgressBar>
          )}

          {formStep === 0 && (
            <section>
              <SectionHeader>
                <FormattedMessage id='userProfile.loginDetails' />
              </SectionHeader>

              <label htmlFor='email'>
                <FormattedMessage id='userProfile.email' />
              </label>
              <input
                type='email'
                autoComplete='off'
                className={`${errors.email ? 'invalid' : ''} ${
                  !errors.email && touchedFields.email ? 'valid' : ''
                }`}
                maxLength='100'
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
                  },
                })}
              />

              <Row>
                <Col>
                  <label htmlFor='password'>
                    <FormattedMessage id='common.password' />
                  </label>
                  <PasswordWrapper>
                    <input
                      className={`${errors.password ? 'invalid' : ''} ${
                        !errors.password && touchedFields.password
                          ? 'valid'
                          : ''
                      }`}
                      autoComplete='off'
                      type={`${showPassword ? 'text' : 'password'}`}
                      ref={password}
                      onKeyDown={handlePasswordKeyDown}
                      {...register('password', {
                        required: true,
                        validate: validatePassword,
                        minLength: {
                          value: 8,
                          message: intl.formatMessage({
                            id: 'common.passwordHint',
                          }),
                        },
                        pattern: {
                          value: passwordRegex,
                          message: intl.formatMessage({
                            id: 'common.passwordHint',
                          }),
                        },
                      })}
                    />
                    {!showPassword && (
                      <Eye
                        src={EyeIcon}
                        alt='Show Password'
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                    {showPassword && (
                      <EyeSlash
                        src={EyeSlashIcon}
                        alt='Hide Password'
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    )}
                  </PasswordWrapper>
                </Col>
                <Col>
                  <label htmlFor='confirmPassword'>
                    <FormattedMessage id='common.confirmPassword' />
                  </label>
                  <PasswordWrapper>
                    <input
                      className={`${errors.confirmPassword ? 'invalid' : ''} ${
                        !errors.confirmPassword && touchedFields.confirmPassword
                          ? 'valid'
                          : ''
                      }`}
                      autoComplete='off'
                      type={`${showConfirmPassword ? 'text' : 'password'}`}
                      onKeyDown={handlePasswordKeyDown}
                      {...register('confirmPassword', {
                        required: true,
                        minLength: {
                          value: 8,
                          message: intl.formatMessage({
                            id: 'common.passwordHint',
                          }),
                        },
                        pattern: {
                          value: passwordRegex,
                          message: intl.formatMessage({
                            id: 'common.passwordHint',
                          }),
                        },
                        validate: (value) =>
                          value === password.current ||
                          intl.formatMessage({
                            id: 'common.passwordDoNotMatch',
                          }),
                      })}
                    />
                    {!showConfirmPassword && (
                      <Eye
                        src={EyeIcon}
                        alt='Show Password'
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    )}
                    {showConfirmPassword && (
                      <EyeSlash
                        src={EyeSlashIcon}
                        alt='Hide Password'
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      />
                    )}
                  </PasswordWrapper>
                </Col>
              </Row>

              {showPasswordLabel &&
                errors?.password?.message !==
                  intl.formatMessage({ id: 'common.passwordHint' }) && (
                  <small>
                    <FormattedMessage id='common.passwordHint' />
                  </small>
                )}

              {(errors?.confirmPassword?.message ||
                errors?.password?.message) && (
                <Error
                  text={`${
                    errors?.confirmPassword?.message ||
                    errors?.password?.message
                  }`}
                />
              )}

              <input
                onClick={async () => {
                  const emailValidation = await trigger('email')
                  const passwordValidation = await trigger('password')
                  const confirmPasswordValidation = await trigger(
                    'confirmPassword'
                  )

                  if (
                    emailValidation &&
                    passwordValidation &&
                    confirmPasswordValidation
                  )
                    setFormStep(formStep + 1)
                }}
                type='button'
                value='Next'
              />
            </section>
          )}

          {formStep === 1 && (
            <section>
              <SectionHeader>
                <FormattedMessage id='userProfile.personalDetails' />
              </SectionHeader>

              <Row>
                <Col>
                  <label htmlFor='name'>
                    <FormattedMessage id='userProfile.name' />
                  </label>
                  <input
                    className={`${errors.name ? 'invalid' : ''} ${
                      !errors.name && touchedFields.name ? 'valid' : ''
                    }`}
                    maxLength='50'
                    autoComplete='off'
                    {...register('name', { required: true })}
                  />
                </Col>
                <Col>
                  <label htmlFor='lastName'>
                    <FormattedMessage id='userProfile.lastName' />
                  </label>
                  <input
                    className={`${errors.lastName ? 'invalid' : ''} ${
                      !errors.lastName && touchedFields.lastName ? 'valid' : ''
                    }`}
                    maxLength='50'
                    autoComplete='off'
                    {...register('lastName', { required: true })}
                  />
                </Col>
              </Row>

              <label htmlFor='day'>
                <FormattedMessage id='userProfile.dob' />
              </label>
              <Row>
                <Col>
                  <select
                    className={`${errors.day ? 'invalid' : ''} ${
                      !errors.day && touchedFields.day ? 'valid' : ''
                    }`}
                    autoComplete='off'
                    {...register('day', { required: true })}
                  >
                    <option value=''>
                      {intl.formatMessage({ id: 'userProfile.day' })}
                    </option>
                    {dates.days.map((row, i) => {
                      return (
                        <option key={i} value={row.value}>
                          {row.label}
                        </option>
                      )
                    })}
                  </select>
                </Col>
                <Col>
                  <select
                    className={`${errors.month ? 'invalid' : ''} ${
                      !errors.month && touchedFields.month ? 'valid' : ''
                    }`}
                    autoComplete='off'
                    {...register('month', { required: true })}
                  >
                    <option value=''>
                      {intl.formatMessage({ id: 'userProfile.month' })}
                    </option>
                    {dates.months.map((row, i) => {
                      return (
                        <option key={i} value={i + 1}>
                          {i + 1}
                        </option>
                      )
                    })}
                  </select>
                </Col>
                <Col>
                  <select
                    className={`${errors.year ? 'invalid' : ''} ${
                      !errors.year && touchedFields.year ? 'valid' : ''
                    }`}
                    autoComplete='off'
                    {...register('year', { required: true })}
                  >
                    <option value=''>
                      {intl.formatMessage({ id: 'userProfile.year' })}
                    </option>
                    {dates.years.map((row, i) => {
                      return (
                        <option key={i} value={row.value}>
                          {row.label}
                        </option>
                      )
                    })}
                  </select>
                </Col>
              </Row>

              <label htmlFor='mobilePrefix'>
                <FormattedMessage id='userProfile.mobileNumber' />
              </label>
              <Row>
                <Col width='32%'>
                  <select
                    className={`${errors.mobilePrefix ? 'invalid' : ''} ${
                      !errors.mobilePrefix && touchedFields.mobilePrefix
                        ? 'valid'
                        : ''
                    }`}
                    autoComplete='off'
                    {...register('mobilePrefix', { required: true })}
                  >
                    <option value=''>--</option>
                    {phoneCodes.map((row, i) => {
                      return row !== '' && '+???' ? (
                        <option key={i} value={row}>
                          {row}
                        </option>
                      ) : (
                        ''
                      )
                    })}
                  </select>
                </Col>
                <Col width='68%'>
                  <input
                    className={`${errors.mobileNumber ? 'invalid' : ''} ${
                      !errors.mobileNumber && touchedFields.mobileNumber
                        ? 'valid'
                        : ''
                    }`}
                    maxLength='32'
                    autoComplete='off'
                    {...register('mobileNumber', { required: true })}
                    onChange={handleMobileNumberChange}
                  />
                </Col>
              </Row>

              <input
                onClick={async () => {
                  const nameValidation = await trigger('name')
                  const lastNameValidation = await trigger('lastName')
                  const dayValidation = await trigger('day')
                  const monthValidation = await trigger('month')
                  const yearValidation = await trigger('year')
                  const mobilePrefixValidation = await trigger('mobilePrefix')
                  const mobileNumberValidation = await trigger('mobileNumber')

                  if (
                    nameValidation &&
                    lastNameValidation &&
                    dayValidation &&
                    monthValidation &&
                    yearValidation &&
                    mobileNumberValidation &&
                    mobilePrefixValidation
                  )
                    setFormStep(formStep + 1)
                }}
                type='button'
                value='Next'
              />
            </section>
          )}

          {formStep === 2 && (
            <section>
              <SectionHeader>
                <FormattedMessage id='userProfile.addressDetails' />
              </SectionHeader>

              <label htmlFor='address'>
                <FormattedMessage id='userProfile.address' />
              </label>
              <input
                className={`${errors.address ? 'invalid' : ''} ${
                  !errors.address && touchedFields.address ? 'valid' : ''
                }`}
                maxLength='128'
                autoComplete='off'
                {...register('address', { required: true })}
              />

              <Row>
                <Col>
                  <label htmlFor='postcode'>
                    <FormattedMessage id='userProfile.postcode' />
                  </label>
                  <input
                    className={`${errors.postcode ? 'invalid' : ''} ${
                      !errors.postcode && touchedFields.postcode ? 'valid' : ''
                    }`}
                    maxLength='16'
                    autoComplete='off'
                    {...register('postcode', { required: true })}
                  />
                </Col>
                <Col>
                  <label htmlFor='city'>
                    <FormattedMessage id='userProfile.city' />
                  </label>
                  <input
                    className={`${errors.city ? 'invalid' : ''} ${
                      !errors.city && touchedFields.city ? 'valid' : ''
                    }`}
                    maxLength='90'
                    autoComplete='off'
                    {...register('city', { required: true })}
                  />
                </Col>
              </Row>

              <Row>
                <Col width='70%'>
                  <label htmlFor='country'>
                    <FormattedMessage id='userProfile.country' />
                  </label>
                  <select
                    className={`${errors.country ? 'invalid' : ''} ${
                      !errors.country &&
                      touchedFields.country &&
                      getValues('country')
                        ? 'valid'
                        : ''
                    }`}
                    autoComplete='off'
                    {...register('country', { required: true })}
                    onChange={handleCountryChange}
                  >
                    <option value=''>--</option>
                    {countries.map((row, i) => {
                      return (
                        <option key={i} value={row.id}>
                          {row.name}
                        </option>
                      )
                    })}
                  </select>
                </Col>
                <Col width='30%'>
                  <label htmlFor='currency'>
                    <FormattedMessage id='userProfile.currency' />
                  </label>
                  <select
                    className={`${errors.currency ? 'invalid' : ''} ${
                      !errors.currency &&
                      touchedFields.currency &&
                      getValues('currency')
                        ? 'valid'
                        : ''
                    }`}
                    autoComplete='off'
                    {...register('currency', { required: true })}
                    onChange={handleCurrencyChange}
                  >
                    <option value=''>--</option>
                    {currencies.map((row, i) => {
                      return (
                        <option key={i} value={row.short_code}>
                          {row.short_code}
                        </option>
                      )
                    })}
                  </select>
                </Col>
              </Row>

              <Row>
                <Col width='70%'>
                  <label htmlFor='region'>
                    <FormattedMessage id='userProfile.region' />
                  </label>
                  <select
                    className={`${errors.region ? 'invalid' : ''} ${
                      !errors.region &&
                      touchedFields.region &&
                      getValues('region')
                        ? 'valid'
                        : ''
                    }`}
                    autoComplete='off'
                    {...register('region', { required: true })}
                    onChange={handleRegionChange}
                  >
                    <option value=''>--</option>
                    {regions.map((row, i) => {
                      return (
                        <option key={i} value={row.name}>
                          {row.name}
                        </option>
                      )
                    })}
                  </select>
                </Col>
                <Col width='30%' />
              </Row>

              <SectionHeader>
                <FormattedMessage id='userProfile.coupon' />
              </SectionHeader>

              <label htmlFor='coupon'>
                <FormattedMessage id='userProfile.couponCode' />
              </label>
              <input
                id='couponCode'
                className={`${errors.coupon ? 'invalid' : ''} ${
                  !errors.coupon && touchedFields.coupon ? 'valid' : ''
                }`}
                maxLength='50'
                {...register('couponCode')}
              />

              <CheckboxWrapper
                onClick={() => setTandcChecked(!tandcChecked)}
                className={`${errors.tandc ? 'invalid' : ''} ${
                  !errors.tandc && touchedFields.tandc ? 'valid' : ''
                }`}
              >
                <input
                  type='checkbox'
                  name='tandc'
                  required
                  onChange={onChange}
                  checked={`${tandcChecked ? 'checked' : ''}`}
                />
                <label htmlFor='tandc'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({ id: 'register.tandc' }),
                    }}
                  />
                </label>
              </CheckboxWrapper>

              <CheckboxWrapper
                onClick={() => setMarketingChecked(!marketingChecked)}
              >
                <input
                  type='checkbox'
                  checked={`${marketingChecked ? 'checked' : ''}`}
                  {...register('marketing')}
                />
                <label htmlFor='marketing'>
                  <FormattedMessage id='register.gdpr' />
                </label>
              </CheckboxWrapper>

              <input
                type='submit'
                form='register-form'
                disabled={disabled}
                className={disabled ? 'disabled' : ''}
                value={intl.formatMessage({ id: 'common.register' })}
              />
            </section>
          )}

          {formStep === 3 && (
            <section>
              <SectionHeader>
                <FormattedMessage id='register.activateYourAccount' />
              </SectionHeader>
              <div>
                <FormattedMessage id='register.weSentAnEmail' />
              </div>
              <Email>{getValues('email')}</Email>

              <Btn
                text={intl.formatMessage({ id: 'register.done' })}
                bgcolor='#31c27c'
                padding='.5rem 2rem'
                onClick={() => navigate(`/${intl.locale}/${loginSlug}/`)}
              />
            </section>
          )}

          {response && !response.ok && (
            <Error text={`${response.error.message}`} />
          )}
        </form>
      </Content>
    </SplitLayout>
  )
}

export default Register
