export const getRegistrationDates = (lang = 'en') => {
  const yearsSpan = 100
  const minAge = 18

  const days = []
  const months = []
  const years = []

  let startYear = 0
  import('dayjs').then(({ default: dayjs }) => {
    startYear = parseInt(
      dayjs(dayjs().subtract(yearsSpan, 'year')).format('YYYY')
    )

    for (let day = 1; day <= 31; day++) {
      days.push({ value: day, label: day })
    }

    const momentLang = lang === 'no' ? 'nb' : lang
    const enGbLang = ['en', 'gb', 'ca', 'nz', 'ie']
    const language = enGbLang.includes(lang) ? 'en-gb' : momentLang
    const locale = require(`dayjs/locale/${language}`)
    dayjs.locale(locale)

    const monthsString = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    for (const month in monthsString) {
      months.push({ value: parseInt(month) + 1, label: monthsString[month] })
    }

    for (let year = 0; year < yearsSpan; year++) {
      const value = startYear + year

      if (value < dayjs().year() - minAge + 1) {
        years.push({ value, label: value })
      }
    }

    years.reverse()
  })

  return {
    days,
    months,
    years,
  }
}
