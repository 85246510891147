module.exports = {
  en: {
    name: 'English',
    code: 'en',
  },
  pt: {
    name: 'Portuguese (Brazil)',
    code: 'pt-br',
  },
  pe: {
    name: 'Spanish (Peru)',
    code: 'es-pe',
  },
  ca: {
    name: 'English (Canada)',
    code: 'en-ca',
  },
  cl: {
    name: 'Spanish (Chile)',
    code: 'es-cl',
  },
}
